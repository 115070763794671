import type { CreateVersionType } from '@readme/api/src/mappings/version/types';

/**
 * Returns an empty `Version` object that's used when creating a new version.
 */
export function createDefaultVersion<Keys extends CreateVersionType>(keys: Partial<Keys> = {}) {
  return {
    base: '',
    display_name: '',
    name: '',
    privacy: { view: 'hidden' },
    release_stage: 'release',
    state: 'current',
    ...keys,
  } satisfies CreateVersionType;
}
